import { configureStore } from '@reduxjs/toolkit'
// import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';
import createSagaMiddleware from 'redux-saga'


import dataReducer from './data'
import userReducer from './user'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


import sagas from '../reducers/sagas'

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
}

export const store = configureStore({
  reducer: {
    data: dataReducer,
    user: persistReducer(persistConfig, userReducer)
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
})


export const persistor = persistStore(store)

sagaMiddleware.run(sagas)



// export default { store, persistor }
import banner from '../data/es/banner.json'
import intro from '../data/es/intro.json'
import aboutus from '../data/es/aboutus.json'
import investmentOptions from '../data/es/investmentOptions.json'
import consumerservices from '../data/es/consumerservices.json'
import process from '../data/es/process.json'
import branches from '../data/es/branches.json'
import blog from '../data/es/blog.json'
import blogcategory from '../data/es/blogcategory.json'
import services from '../data/es/services.json'
import accounttypes from '../data/es/accounttypes.json'
import internationalwiretransfer from '../data/es/internationalwiretransfer.json'
import contactinfo from '../data/es/contactinfo.json'

import en_banner from '../data/en/banner.json'
import en_intro from '../data/en/intro.json'
import en_aboutus from '../data/en/aboutus.json'
import en_investmentOptions from '../data/en/investmentOptions.json'
import en_consumerservices from '../data/en/consumerservices.json'
import en_process from '../data/en/process.json'
import en_branches from '../data/en/branches.json'
import en_blog from '../data/en/blog.json'
import en_blogcategory from '../data/en/blogcategory.json'
import en_services from '../data/en/services.json'
import en_accounttypes from '../data/en/accounttypes.json'
import en_internationalwiretransfer from '../data/en/internationalwiretransfer.json'
import en_contactinfo from '../data/en/contactinfo.json'
import twitterfeed from '../data/twitterfeed.json'


const data = {
    "es_banner": banner,
    "es_blog": blog,
    "es_blogcategory": blogcategory,
    "es_intro": intro,
    "es_process": process,
    "es_services": services,
    "es_consumerservices": consumerservices,
    "es_twitterfeed": twitterfeed,
    "es_aboutus": aboutus,
    "es_investmentOptions": investmentOptions,
    "es_branches": branches,
    "es_accounttypes": accounttypes,
    "es_internationalwiretransfer": internationalwiretransfer,
    "es_contactinfo": contactinfo,

   
    "en_banner": en_banner,
    "en_blog": en_blog,
    "en_blogcategory": en_blogcategory,
    "en_intro": en_intro,
    "en_process": en_process,
    "en_services": en_services,
    "en_consumerservices": en_consumerservices,
    "en_twitterfeed": twitterfeed,
    "en_aboutus": en_aboutus,
    "en_investmentOptions": en_investmentOptions,
    "en_branches": en_branches,
    "en_accounttypes": en_accounttypes,
    "en_internationalwiretransfer": en_internationalwiretransfer,
    "en_contactinfo": en_contactinfo,
}

export default data;